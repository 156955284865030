import React from "react";

// @ts-ignore
function GLOBALTEAM({ globalteam, index }) {
  return (
    <div
      className="ind-retailer"
      key={index}
    >
      
      <a href={globalteam.link}><img src={globalteam.image} alt={globalteam.title} /></a>
    </div>
    
  );
}

export default GLOBALTEAM;
