import React from 'react';

interface IProps {
  id?:any;
  theme?:any;
}

interface IState {
  ready?:any;
}

class ConstantContactForm extends React.Component<IProps>{

  public state: IState;

  constructor(props:any) {
    super(props);
      this.state = {ready:false};
  }
  public componentDidMount = () => {
    if (typeof window !== 'undefined') {
      const script1:any = document.createElement("script");
      script1.text = 'var _ctct_m = "8c5b16ae8f14401985b3ba0f02375a3b";';
      document.head.appendChild(script1);

      const script2:any = document.createElement("script");
      script2.src = "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
      script2.async = true;
      script2.defer = true;
      document.head.appendChild(script2);
      this.setState({
        ready:true
      },()=>{
        setTimeout(() => {
          let elem:any = document.getElementById('email_address_3');
          if(elem){
            if(elem){
              // @ts-ignore
              elem.placeholder = "Enter Your email"
            }
          }
        }, 2000);
      })
    }
  }
	public render(): JSX.Element {
    let id:any = this.props.id;
    let theme:any = "dark";
    if(this.props.theme){
      theme = this.props.theme
    }
    return (
			<div className={"small-padding constant-contact-form "+theme}>
				<div className="small-max-width-container" style={{maxWidth:"600px"}}>
					<p className="white centered-text">{'JOIN OUR NEWSLETTER'}</p>
					{this.state.ready && <div className="ctct-inline-form" data-form-id={id}></div>}
				</div>
			</div>
		)
	}
}

 export default ConstantContactForm;
